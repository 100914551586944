import React from "react"
import { Link, graphql } from "gatsby"
import { connect } from 'react-redux'

import Layout from "../components/layout"
import SEO from "../components/seo"
import { rhythm, scale } from "../utils/typography"
import { attemptLogin, attemptLogout, blogPostPageLoaded } from '../actions'
import { DiscussionEmbed } from 'disqus-react'

class BlogPostTemplate extends React.Component {
  componentDidMount() {
    this.props.blogPostPageLoaded()
  }

  render() {
    const {
      data,
      pageContext,
      location,
      isLoggedIn,
      attemptLogin,
      attemptLogout,
      user,
    } = this.props
    const post = data.markdownRemark
    const siteTitle = data.site.siteMetadata.title
    const { previous, next } = pageContext
  
    return (
      <Layout location={location} title={siteTitle}>
        <SEO
          title={post.frontmatter.title}
          description={post.excerpt}
        />
        <article>
          <header>
            <h1
              style={{
                marginTop: rhythm(1),
                marginBottom: 0,
              }}
            >
              {post.frontmatter.title}
            </h1>
            <p
              style={{
                ...scale(-1 / 5),
                display: `block`,
                marginBottom: rhythm(1),
              }}
            >
              {post.frontmatter.date}
            </p>
          </header>
          <section dangerouslySetInnerHTML={{ __html: post.html }} />
        </article>

        {/* <AuthSection
          isLoggedIn={isLoggedIn}
          user={user}
          attemptLogin={attemptLogin}
          attemptLogout={attemptLogout} /> */}
  
        <nav>
          <ul
            style={{
              display: `flex`,
              flexWrap: `wrap`,
              justifyContent: `space-between`,
              listStyle: `none`,
              padding: 0,
            }}
          >
            <li>
              {previous && (
                <Link to={previous.fields.slug} rel="prev">
                  ← {previous.frontmatter.title}
                </Link>
              )}
            </li>
            <li>
              {next && (
                <Link to={next.fields.slug} rel="next">
                  {next.frontmatter.title} →
                </Link>
              )}
            </li>
          </ul>
        </nav>

        <DiscussionEmbed
            shortname='suvish-in'
            config={{
                url: pageContext.fullUrl,
                identifier: pageContext.slug,
                title: post.frontmatter.title,
            }}
        />
      </Layout>
    )
  }
}

const AuthSection = ({ isLoggedIn, user, attemptLogin, attemptLogout }) => {
  const userDisplayName = user && user.displayName
  const LoggedInSection = () => (
    <div>
      <p>
        You're logged in as {userDisplayName}.
        <button onClick={() => attemptLogout()}>Logout?</button>
      </p>
    </div>
  );
  return (
    <div>
      {
        isLoggedIn ? 
        <LoggedInSection /> :
        <button onClick={() => attemptLogin()}>Login with email</button>
      }
      <div id="firebaseui-auth-container" />
    </div>
  )
}

const mapStateToProps = ({ auth: { isLoggedIn, user }}) => ({ isLoggedIn, user })
const mapDispatchToProps = { attemptLogin, attemptLogout, blogPostPageLoaded }

const BlogPostTemplateWrapper = connect(mapStateToProps, mapDispatchToProps)(BlogPostTemplate)

export default BlogPostTemplateWrapper

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
      }
    }
  }
`
